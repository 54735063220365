import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
// import { ConnectButton } from '@rainbow-me/rainbowkit';

export function isConnected(appState) {
  const { blockchain } = appState;
  return !(blockchain?.account === "" || blockchain?.smartContract === null);
}

export function ConnectWalletButton({appState}) {
  const dispatch = useDispatch();
  const { getData, blockchain } = appState;

  return (
    <div className="flex">
      <button
        className="bg-ark-pink text-white p-2 uppercase max-w-max select-none"
        onClick={(e) => {
          e.preventDefault();
          dispatch(connect());
          getData();
        }}
      >
        Connect Wallet
      </button>
      {blockchain.errorMsg !== "" ? (
        <>
          <div className="text-center text-white bg-ark-pink/40 p-2 select-none">
            {blockchain.errorMsg}
          </div>
        </>
      ) : null}
    </div>
  )
}

export function MintButton({appState}) {
  const { feedback, getData, claimingNft, claimNFTs } = appState;

  return (
    <div className="flex">
      <button
        disabled={claimingNft ? 1 : 0}
        onClick={(e) => {
          e.preventDefault();
          claimNFTs();
          getData();
        }}
        className={`bg-ark-pink transition duration-300 ease-in-out hover:bg-ark-pink/80 text-white p-2 uppercase max-w-max ${claimingNft ? "animate-pulse": ""}`}
      >
        {claimingNft ? "MINTING" : "MINT"}
      </button>
      <div className={`text-center text-white bg-ark-pink/60 p-2 ${claimingNft ? "bg-ark-pink/100 animate-pulse": ""}`}>
        {feedback}
      </div>
    </div>
  )
}

export default function MintForm({appState}) {
  return (
    <>
      {!isConnected(appState) ? ( 
          <ConnectWalletButton appState={appState} />
        ) : (
          <MintButton appState={appState} />
        )
      }
    </>
  )
}
