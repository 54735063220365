// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      let NFTPromises = [];
      let NFTOwnerPromises = [];
      for (let i = 1; i <= totalSupply; i++) {
        NFTOwnerPromises.push(
          store
          .getState()
          .blockchain.smartContract.methods.ownerOf(i)
          .call()
        );
        NFTPromises.push(
          store
          .getState()
          .blockchain.smartContract.methods.tokenURI(i)
          .call()
        );
      }
      const NFTs = await Promise.all(NFTPromises);
      const OwnerNFTs = await Promise.all(NFTOwnerPromises);
      console.log(OwnerNFTs)
      dispatch(
        fetchDataSuccess({
          totalSupply,
          NFTs,
          OwnerNFTs,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
