import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";

import MintPage from "./pages/mint";
import ExplorePage from "./pages/explore";
import MyArksPage from "./pages/myArks";
// import MintForm, { isConnected } from "./components/walletButtons";

import BlobRight from "./assets/arkBlobRight.svg";
import BlobLeft from "./assets/arkBlobLeft.svg";
import Footer from "./components/footer";
// import '@rainbow-me/rainbowkit/dist/index.css';

// import {
//   getDefaultWallets,
//   RainbowKitProvider,
//   darkTheme,
//   nightTheme,
// } from '@rainbow-me/rainbowkit';
// import {
//   chain,
//   configureChains,
//   createClient,
//   WagmiConfig,
// } from 'wagmi';
// import { alchemyProvider } from 'wagmi/providers/alchemy';
// import { publicProvider } from 'wagmi/providers/public';
// import { ConnectButton } from '@rainbow-me/rainbowkit';

function App() {
  
  const dispatch = useDispatch();

  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [knownArks, setknownArks] = useState();
  const [knownOwners, setknownOwners] = useState()
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Ark awaits you!`);
  const [mintAmount, setMintAmount] = useState(1);
  const [currentTab, setCurrentTab] = useState(0);
  const tab = (n) => currentTab === n;

  const [CONFIG, SET_CONFIG] = useState({
    OWNER_ADDRESS: "",
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 43,
    //PRE_MINT_SUPPLY: 9,
    WEI_COST: "300000000000000000",
    DISPLAY_COST: 0.3,
    GAS_LIMIT: 0, 
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Error: Transaction rejected / failed.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Congratulations! You've minted ${CONFIG.NFT_NAME}!`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  // silly hack but should work for now
  const appState = {
    CONFIG: CONFIG,
    blockchain: blockchain,
    data: data,
    knownArks: knownArks,
    knownOwners: knownOwners,
    claimingNft: claimingNft,
    feedback: feedback,
    setFeedback: setFeedback,
    claimNFTs: claimNFTs,
    getData: getData,
    getConfig: getConfig,
  }

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    if (data?.NFTs) {
      async function fetchNFTs() {
        let promises = [];
        const promise = (nftURI) => fetch(nftURI.replace('.json', '')).then((res) => res.text());
        data.NFTs.map((nftURI) => {
          promises.push(promise(nftURI));
        });
        setknownOwners(data.OwnerNFTs)
        const responses = await Promise.all(promises);

        const r = responses.map(r => {
          try {
            return JSON.parse(r);
          }
          catch (e) {
            console.log(e);
            return null
          }
        })
        setknownArks(r.filter(n => n));
      };
      fetchNFTs();
    }
  }, [data])

  // <div className="absolute animate-pulse bg-gradient-to-r from-cyan-500 to-blue-500 -skew-y-[40deg] h-20 w-[40rem] opacity-5 -z-10 overflow-y-hidden"></div>

  return (
    <div className="h-screen bg-ark-purple font-mono">
      <div className="h-full relative overflow-x-hidden">
        <div className="absolute top-0 -left-[400px] w-[800px] h-[100px] pointer-events-none">
          <img src={BlobLeft} className="animate-pulse-light" />
        </div>
        <div className="absolute top-20 -right-[400px] w-[800px] h-[100px] pointer-events-none">
          <img src={BlobRight} className="animate-pulse-light" />
        </div>
        <div className="flex flex-col justify-center w-full z-10">
          <div className="flex flex-col xs:flex-row justify-between items-center xs:w-[360px] mx-auto font-semibold mt-14 select-none min-w-max">
            {["Mint", "Explore", "My Arks"].map((item, idx) => (
              <button
                key={idx}
                onClick={() => setCurrentTab(idx)}
                disabled={tab(idx)}
                className={(tab(idx) ? "text-ark-green border-b-2 border-b-ark-green": "text-white") + " transition duration-300 ease-in-out hover:text-ark-green-focused text-2xl"}
              >{item}</button>
            ))}
          </div>
          <div className={`${currentTab !== 0 ? "mx-10" : "mx-2"}`}>
            {[<MintPage appState={appState} />, <ExplorePage appState={appState} />, <MyArksPage appState={appState} />][currentTab]}
          </div>
        </div>
        <div className={currentTab !== 0 ? "mt-44" : "mt-12"}>
          <Footer />
        </div>
      </div>
    </div>
  )

  // return (
  //   <s.Screen>
  //     <s.Container
  //       flex={1}
  //       ai={"center"}
  //       style={{ padding: 24, backgroundColor: "var(--primary)" }}
  //       image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
  //     >
  //       <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
  //       <s.SpacerSmall />
  //       <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
  //         <s.Container flex={1} jc={"center"} ai={"center"}>
  //           <StyledImg alt={"example"} src={"/config/images/example.gif"} />
  //         </s.Container>
  //         <s.SpacerLarge />
  //         <s.Container
  //           flex={2}
  //           jc={"center"}
  //           ai={"center"}
  //           style={{
  //             backgroundColor: "var(--accent)",
  //             padding: 24,
  //             borderRadius: 24,
  //             border: "4px dashed var(--secondary)",
  //             boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
  //           }}
  //         >
  //           <s.TextTitle
  //             style={{
  //               textAlign: "center",
  //               fontSize: 50,
  //               fontWeight: "bold",
  //               color: "var(--accent-text)",
  //             }}
  //           >
  //             {data.totalSupply} / {CONFIG.MAX_SUPPLY}
  //           </s.TextTitle>
  //           <s.TextDescription
  //             style={{
  //               textAlign: "center",
  //               color: "var(--primary-text)",
  //             }}
  //           >
  //             <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
  //               {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
  //             </StyledLink>
  //           </s.TextDescription>
  //           <s.SpacerSmall />
  //           {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
  //             <>
  //               <s.TextTitle
  //                 style={{ textAlign: "center", color: "var(--accent-text)" }}
  //               >
  //                 The sale has ended.
  //               </s.TextTitle>
  //               <s.TextDescription
  //                 style={{ textAlign: "center", color: "var(--accent-text)" }}
  //               >
  //                 You can still find {CONFIG.NFT_NAME} on
  //               </s.TextDescription>
  //               <s.SpacerSmall />
  //               <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
  //                 {CONFIG.MARKETPLACE}
  //               </StyledLink>
  //             </>
  //           ) : (
  //             <>
  //               <s.TextTitle
  //                 style={{ textAlign: "center", color: "var(--accent-text)" }}
  //               >
  //                 1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
  //                 {CONFIG.NETWORK.SYMBOL}.
  //               </s.TextTitle>
  //               <s.SpacerXSmall />
  //               <s.TextDescription
  //                 style={{ textAlign: "center", color: "var(--accent-text)" }}
  //               >
  //                 Excluding gas fees.
  //               </s.TextDescription>
  //               <s.SpacerSmall />
  //               {blockchain.account === "" ||
  //               blockchain.smartContract === null ? (
  //                 <s.Container ai={"center"} jc={"center"}>
  //                   <s.TextDescription
  //                     style={{
  //                       textAlign: "center",
  //                       color: "var(--accent-text)",
  //                     }}
  //                   >
  //                     Connect to the {CONFIG.NETWORK.NAME} network
  //                   </s.TextDescription>
  //                   <s.SpacerSmall />
  //                   <StyledButton
  //                     onClick={(e) => {
  //                       e.preventDefault();
  //                       dispatch(connect());
  //                       getData();
  //                     }}
  //                   >
  //                     CONNECT
  //                   </StyledButton>
  //                   {blockchain.errorMsg !== "" ? (
  //                     <>
  //                       <s.SpacerSmall />
  //                       <s.TextDescription
  //                         style={{
  //                           textAlign: "center",
  //                           color: "var(--accent-text)",
  //                         }}
  //                       >
  //                         {blockchain.errorMsg}
  //                       </s.TextDescription>
  //                     </>
  //                   ) : null}
  //                 </s.Container>
  //               ) : (
  //                 <>
  //                   <s.TextDescription
  //                     style={{
  //                       textAlign: "center",
  //                       color: "var(--accent-text)",
  //                     }}
  //                   >
  //                     {feedback}
  //                   </s.TextDescription>
  //                   <s.SpacerMedium />
  //                   <s.Container ai={"center"} jc={"center"} fd={"row"}>
  //                     <StyledRoundButton
  //                       style={{ lineHeight: 0.4 }}
  //                       disabled={claimingNft ? 1 : 0}
  //                       onClick={(e) => {
  //                         e.preventDefault();
  //                         decrementMintAmount();
  //                       }}
  //                     >
  //                       -
  //                     </StyledRoundButton>
  //                     <s.SpacerMedium />
  //                     <s.TextDescription
  //                       style={{
  //                         textAlign: "center",
  //                         color: "var(--accent-text)",
  //                       }}
  //                     >
  //                       {mintAmount}
  //                     </s.TextDescription>
  //                     <s.SpacerMedium />
  //                     <StyledRoundButton
  //                       disabled={claimingNft ? 1 : 0}
  //                       onClick={(e) => {
  //                         e.preventDefault();
  //                         incrementMintAmount();
  //                       }}
  //                     >
  //                       +
  //                     </StyledRoundButton>
  //                   </s.Container>
  //                   <s.SpacerSmall />
  //                   <s.Container ai={"center"} jc={"center"} fd={"row"}>
  //                     <StyledButton
  //                       disabled={claimingNft ? 1 : 0}
  //                       onClick={(e) => {
  //                         e.preventDefault();
  //                         claimNFTs();
  //                         getData();
  //                       }}
  //                     >
  //                       {claimingNft ? "BUSY" : "BUY"}
  //                     </StyledButton>
  //                   </s.Container>
  //                 </>
  //               )}
  //             </>
  //           )}
  //           <s.SpacerMedium />
  //         </s.Container>
  //         <s.SpacerLarge />
  //         <s.Container flex={1} jc={"center"} ai={"center"}>
  //           <StyledImg
  //             alt={"example"}
  //             src={"/config/images/example.gif"}
  //             style={{ transform: "scaleX(-1)" }}
  //           />
  //         </s.Container>
  //       </ResponsiveWrapper>
  //       <s.SpacerMedium />
  //       <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
  //         <s.TextDescription
  //           style={{
  //             textAlign: "center",
  //             color: "var(--primary-text)",
  //           }}
  //         >
  //           Please make sure you are connected to the right network (
  //           {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
  //           Once you make the purchase, you cannot undo this action.
  //         </s.TextDescription>
  //         <s.SpacerSmall />
  //         <s.TextDescription
  //           style={{
  //             textAlign: "center",
  //             color: "var(--primary-text)",
  //           }}
  //         >
  //           We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
  //           successfully mint your NFT. We recommend that you don't lower the
  //           gas limit.
  //         </s.TextDescription>
  //       </s.Container>
  //     </s.Container>
  //   </s.Screen>
  // );
}

export default App;
