import { useState, useEffect } from 'react';
import Typewriter from 'typewriter-effect';
import ArkPlaceholder2 from '../assets/arkPlaceholder2.jpeg';
import ArkView from '../components/arkView';
import MintForm, { WalletConnectButton, isConnected } from '../components/walletButtons';
import ConnectWalletInfoBox from '../components/connectWalletInfoBox';
import { conditionColorPicker } from '../components/utils.js';
import { SparklesIcon, HashtagIcon, UserGroupIcon, IdentificationIcon, ScaleIcon, OfficeBuildingIcon} from '@heroicons/react/outline';

function ExplorePage({appState, myArks=false}) {
  const [showArks, setshowArks] = useState();
  const { blockchain, CONFIG, data, knownOwners } = appState;
  const MESON_ENDPOINT = 'https://pz-znmpfs.meson.network/'
  const ARWEAVE_ENDPOINT = 'https://arweave.net/'
  const SMALL_IMGS = 'otYGDafek7-rUBj6PR5_xuLwMpxvJI6d-KB20O7FMJc';
  
  let knownArks = myArks ? 
    (appState.knownArks && knownOwners && blockchain.account ? 
      appState.knownArks.filter((ark, idx) => knownOwners[idx]?.toLowerCase() == blockchain.account.toLowerCase()) : []
    )
    :
  (appState.knownArks ? appState.knownArks: []);

  const resizeImage = (image) => { 
    return image
    .replace(ARWEAVE_ENDPOINT, MESON_ENDPOINT)
    .replace("0zrhuAxJoZ78qKgie2xotUkmxSotyVi-vwkTH6U7a9U", SMALL_IMGS)
    .replace("GLALaPIzfK47J3Je_zhmVf3Sgahi0aRdhaRfVq_48oE", SMALL_IMGS)
    // `https://pz-znmpfs.meson.network/${ark.image.replace("", "").replace("0zrhuAxJoZ78qKgie2xotUkmxSotyVi-vwkTH6U7a9U", SMALL_IMGS).replace("GLALaPIzfK47J3Je_zhmVf3Sgahi0aRdhaRfVq_48oE", SMALL_IMGS)}`:  ark.image
   }

  const [isEmpty, setIsEmpty] = useState(false);
  const [currentArk, setcurrentArk] = useState(0);
  const [arkGridView, setArkGridView] = useState(true);

  const nextArk = () => setcurrentArk(Math.abs((currentArk + 1) % knownArks.length));
  const prevArk = () => setcurrentArk(currentArk > 0 ? currentArk - 1: knownArks.length-1);
  const ark = knownArks[currentArk];
  const string = myArks ? 'My <span class="bg-ark-pink px-1 py-0.25">Arks</span>' : 'Manifest of <span class="bg-ark-green text-ark-purple p-1">known arks</span>';
  const icons = [<OfficeBuildingIcon className="w-4 h-4 shrink-0" />, <SparklesIcon className="w-4 h-4 shrink-0" />, <ScaleIcon className="w-4 h-4 shrink-0" />, <UserGroupIcon className="w-4 h-4 shrink-0" />, <IdentificationIcon className="w-4 h-4 shrink-0" />];

  // in case there are no arks, show a mint form
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (knownArks?.length === 0) {
        setIsEmpty(true)
      }
    }, 7000);

    return () => clearTimeout(timeout);
  }, [])

  return (
    <div className="mt-16 flex flex-col items-center justify-center">
      <h1 className="mb-4 font-mono text-center text-3xl md:text-4xl text-gray-100 lg:text-6xl">
        <Typewriter
          options={{delay: 20}}
          onInit={
            (typewriter) => typewriter
            .typeString(string)
            .callFunction(() => {
              setshowArks(true);
            })
            .pauseFor(myArks ? 2350 : 2000)
            .callFunction(function(state) {
              state.elements.cursor.style.visibility = 'hidden';
            })
            .start()
          }
        />
      </h1>
      {!myArks && 
        <div className={`text-gray-400 landing-4 font-thin transparent transition-opacity mt-6 duration-[1.1s] ease-in-out ${showArks ? "opacity-100": "opacity-0"}`}>A humming database module in the Office quietly tracks every member of the fleet. Every ark minted so far is shown below.</div>
      }
      <div className={`w-full mx-5 md:mx-[53px] transparent mt-20 transition-transform duration-[1.1s] ease-in-out ${showArks ? "-translate-y-6": ""}`}>
        <div className={`transparent transition-opacity duration-[1.1s] ease-in-out ${showArks ? "opacity-100": "opacity-0"}`}>
          {isConnected(appState) ? 
            (arkGridView ? (
              <div className="flex justify-center w-full relative">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-9 gap-y-6">
                  {knownArks && knownArks.length > 0 ? (
                      knownArks.map((ark, index) => (
                        <div 
                          key={index}
                          onClick={() => {
                            setArkGridView(false)
                            setcurrentArk(index);
                          }}
                          className="relative z-0 rounded-3xl bg-ark-purple-focused/90 p-4 cursor-pointer transition-all h-[460px] w-[290px] duration-500 hover:-translate-y-1 hover:drop-shadow-[0_10px_10px_rgba(0,255,87,0.5)]"
                        >
                          <div className="h-full w-full">
                            <img src={ resizeImage(ark.image) } className="rounded-2xl" />
                            <div className="text-white px-2 text font-bold mt-4">
                              <h1 className="h-14 line-clamp-2">{ark.name}</h1>
                              <div className="grid grid-cols-2">
                                <div className="text-white flex text-[11px] mt-1.5 pb-1.5 tooltip" data-tip="Edition">
                                  <HashtagIcon className="text-white h-4 w-4" />
                                  <span className="ml-2">{index+1}</span>
                                </div>  
                                {ark?.attributes?.map((attr, index) => (
                                  <div key={index} className="text-white flex text-[11px] mt-1 pb-1 px-0.5">
                                    {icons[index]}
                                    <span className={`ml-2 break-all tooltip text-left ` + conditionColorPicker(attr.value)} data-tip={attr.trait_type}>{attr.value} {attr.trait_type == "Size" && " size"} {attr.trait_type == "Population" && " pop."}</span>
                                  </div>
                                ))}
                              </div>  
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <>
                        {isEmpty ? (
                          <div className="col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4 flex flex-col items-center">
                            <div className="text-center mb-4">
                              {myArks ? ("You don't have any Arks yet") : ("No Arks minted yet.")}
                            </div>
                            <div>
                              <div className="mt-3 mb-5 text-ark-green text-lg text-center md:text-start">
                                Mint 1: Ξ {CONFIG?.DISPLAY_COST} {data?.totalSupply ? <span>({CONFIG.MAX_SUPPLY - data?.totalSupply} left) {CONFIG.MAX_SUPPLY - data?.totalSupply == 1 && <span className="bg-ark-pink/60 p-2 animate-pulse">Last one</span>}</span> : ''}
                              </div>
                              {Number(data.totalSupply) >= CONFIG?.MAX_SUPPLY ? (
                                <div className="text-white">
                                  The sale has ended.
                                  You can still find {CONFIG?.NFT_NAME} on
                                  <a className="bg-ark-pink/80 py-2 px-0.5 ml-2" target={"_blank"} href={CONFIG?.MARKETPLACE_LINK}>
                                    {CONFIG?.MARKETPLACE}
                                  </a>
                                </div>
                              ) : (
                                <div className="flex justify-center md:block">
                                  <MintForm appState={appState} />
                                </div>
                              )}
                            </div>
                          </div>
                        ): (
                          <>
                            {['1','2', '3', '4'].map((ark, index) => (
                              <div key={index} className="bg-ark-purple-focused/50 pl-1 h-[460px] w-[290px] rounded-3xl transition-all duration-200 hover:drop-shadow-[0_10px_10px_rgba(0,255,87,0.1)] animate-pulse"><div className="flex justify-center items-center animate-spin h-2 mt-60">/</div></div>
                            ))}
                          </>
                        )}
                      </>
                    )
                  }
                </div>
              </div>
            ):(
              <>
                <ArkView ark={ark} arkURI={CONFIG.MARKETPLACE_LINK} nextArk={nextArk} prevArk={prevArk} close={() => setArkGridView(true)} />
              </>
            )
          ): (
            <ConnectWalletInfoBox appState={appState} />
          )}
        </div>
      </div>
    </div>
  )
}

export default ExplorePage;