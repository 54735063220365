import React, {useState} from 'react';
import RightArrow from '../assets/rightArrow.svg';
import LeftArrow from '../assets/leftArrow.svg';

export default function ArkView({ark, arkURI, nextArk, prevArk, close}) {
  const [readMore, setReadMore] = useState(false);
  console.log(ark.description);

  return (
    <div className="relative w-full border border-1 border-ark-green bg-white/10 p-8 flex flex-col items-center lg:flex-row lg:items-start text-center md:text-start">
      <img src={ark?.image} className="max-h-[420px] max-w-[420px] h-full w-full mr-0 md:mr-6" />
      <div className="flex flex-col mt-8 mr-0 md:mr-6">
        <div className="text-2xl text-ark-green-focused font-bold mb-8">
          {ark?.name}
        </div>
        <div style={{'white-space': 'pre-wrap'}} className={`text-white font-light leading-4 text-sm ${readMore ? "": "line-clamp-[11]"} `}>
          {ark?.description}
        </div>
        <div className="flex items-center flex-col-reverse md:flex-row font-bold text-white mt-8  ">
          <a href={arkURI} className="text-center border border-1 border-white md:mr-6 px-3 py-1 uppercase max-w-[200px] w-full ">
            Check on OpenSea
          </a>
          <button className={`text-center bg-ark-pink px-5 mt-2 md:mt-0 py-1 uppercase max-w-[200px] w-full ${readMore ? "hidden": "block"}`} onClick={() => setReadMore(true)}>
            Read more
          </button>
        </div>
        <button className="absolute top-1 right-1 border-2 border-white px-2 text-white" onClick={close}>
          X
        </button>
        <button className="absolute top-1/2 -right-5 bg-ark-green p-3" onClick={nextArk}>
          <img src={RightArrow} className="w-4 h-4" alt="" />
        </button>
        <button className="absolute top-1/2 -left-5 bg-ark-green p-3" onClick={prevArk}>
          <img src={LeftArrow} className="w-4 h-4" alt="" />
        </button>
      </div>
    </div>
  )
};
