export const conditionColorPicker = (condition) => {
  const shadow = (color) =>  color + "";

  switch (condition) {
    case 'Clean': return "text-ark-green drop-shadow-[0_0px_2px_rgba(72,215,95,0.5)]";
    case 'Immaculate': return "text-yellow-400 animate-pulse drop-shadow-[0_0px_2px_rgba(250,204,21,0.8)]";
    case 'Blazed': return "text-amber-600 drop-shadow-[0_0px_2px_rgba(217,119,6,0.5)]";
    case 'Devastated': return "text-yellow-700 drop-shadow-[0_0px_2px_rgba(250,204,21,0.3)]";
    case 'Enwebbed': return "text-white drop-shadow-[0_0px_2px_rgba(255,255,255,0.5)]";
    case 'Amputee': return "text-pink-700 drop-shadow-[0_0px_2px_rgba(190,24,93,0.5)]";
    case 'Scarred': return "text-rose-800 drop-shadow-[0_0px_2px_rgba(159,18,57,0.5)]";
    case 'Diseased': return "text-gray-600 drop-shadow-[0_0px_2px_rgba(77,77,77,0.5)]";
  };
};
