import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Typewriter from 'typewriter-effect';
import ArkMintPlaceholder from '../assets/arkMintPlaceholder.jpeg'
import MintForm from '../components/walletButtons';
import Placeholder from '../assets/Arkade.png'

function MintPage({ appState }) {
  const { CONFIG, data } = appState;
  const [showDescription, setshowDescription] = useState();
  const [showSecondDescription, setshowSecondDescription] = useState();
  
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px',
  });

  return (
    <>
      <div className="mt-20 mx-2 md:mx-10 flex justify-center flex-col-reverse md:flex-row">
        <div className="lg:max-w-[36.5rem] w-full mr-0 md:mr-12 mb-8 md:mb-0 md:h-[500px]">
          <h1 className="mb-2 landing-4 font-mono text-3xl text-gray-100 md:text-4xl lg:text-6xl">
            <Typewriter
              options={{ delay: 20 }}
              onInit={(typewriter) => typewriter
                .typeString(`Own one of the first <span class="bg-ark-green text-ark-purple px-1 py-0.25">${CONFIG.MAX_SUPPLY} arks</span> to reach decent land.`)
                .callFunction(() => {
                  setshowDescription(true);
                })
                .pauseFor(2000)
                .callFunction(function (state) {
                  state.elements.cursor.style.visibility = 'hidden';
                })
                .start()}
            />
          </h1>
          <div className={`mt-4 transition-opacity ease-in duration-[1.1s] ${showDescription ? "opacity-100" : "opacity-0"}`}>
            <p className="text-gray-400 landing-4 font-thin lg:max-w-[34rem] text-xs lg:text-sm">
              “It was the binary star that attracted our ship. Its perpendicular twirls winked from a cosmic horizon. Then, our ark was on its five-hundredth generation (or somewhere thereabouts). Now we’re nearing six-hundred — a milestone — and the system is closer in reach. We sucked the sap of innumerable suns on a pinball trajectory to hope. Two at once may just be enough to sustain our enormous demands. There, we thought, we can finally settle; allow our ships to drift. Perhaps we can build a harnessing ring, and mould — from the remains of our graveyard fleet — a home for ourselves by the twins.”
            </p>
            <div className="mt-3 mb-5 text-ark-green text-lg text-center md:text-start">
              Mint 1: Ξ {CONFIG?.DISPLAY_COST} {data?.totalSupply ? <span>({CONFIG.MAX_SUPPLY - data?.totalSupply} left) {CONFIG.MAX_SUPPLY - data?.totalSupply == 1 && <span className="bg-ark-pink/60 p-2 animate-pulse">Grab the last one!</span>}</span> : ''}
            </div>
            {Number(data.totalSupply) >= CONFIG?.MAX_SUPPLY ? (
              <div className="text-white">
                The sale has ended.
                You can still find {CONFIG?.NFT_NAME} on
                <a className="bg-ark-pink/80 py-2 px-0.5 ml-2" target={"_blank"} href={CONFIG?.MARKETPLACE_LINK}>
                  {CONFIG?.MARKETPLACE}
                </a>
              </div>
            ) : (
              <div className="flex justify-center md:block">
                <MintForm appState={appState} />
              </div>
            )}
          </div>
        </div>
        <div className="relative mb-12 max-h-[80vw] h-[550px] max-w-[566px] w-3/4 md:w-full md:h-full flex mx-auto lg:block lg:mx-0">
          <div className="absolute z-20 max-h-[550px] max-w-[550px]">
            <img src={ArkMintPlaceholder} className="aspect-square w-full h-full" alt="ArkMintPlaceholder" />
          </div>
          <div className="aspect-square absolute z-10 border border-1 border-ark-green top-4 left-4 max-w-[550px] w-full">
            <div className="aspect-square max-w-[550px] w-full">
              <div className="aspect-square bg-white/10 bg-stripes max-w-[550px] w-full"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-20 mb-12 mx-2 md:mx-10 flex justify-center flex-col md:flex-row items-center md:items-start lg:mt-40">
        <div className="max-h-[520px] max-w-[520px] border border-1 border-ark-green mr-0 md:mr-8">
          <img src={Placeholder} className="h-full w-full" />
        </div>
        <div className="xs:p-0 pb-12 container px-6 mx-auto xs:text-sm sm:text-sm">
          <div className={`text-white text-center mt-24 mb-4 text-3xl font-medium`}>
            <div ref={ref}>
              {inView ? (
                <Typewriter
                  options={{ delay: 10 }}
                  onInit={(typewriter) => typewriter
                    .typeString(`Limited-edition <span class="bg-ark-green text-black">early adopter passes</span> for decent.land scouts.`)
                    .callFunction(() => {
                      setshowSecondDescription(true);
                    })
                    .pauseFor(2000)
                    .callFunction(function (state) {
                      state.elements.cursor.style.visibility = 'hidden';
                    })
                    .start()}
                />
              ) : null}
            </div>
          </div>
          <div className={`text-gray-300 text-center mt-4 mb-4 text-xs lg:text-sm font-small  transition-opacity ease-in duration-[1.1s] ${showSecondDescription ? "opacity-100" : "opacity-0"}`}>
            Arks are a collection of the first 43 ships to reach Yowunas Maias after hundreds of generations of searching for a place to settle and build life. Hosted on Arweave and minted on Ethereum, Arks are early adopter passes for decent.land – a stack of social and identity protocols for web3 dapps. <p className="mt-3 font-bold">Ark holders are whitelisted for future decent.land collections, tokens, and more.</p>
          </div>
          <div className={`text-gray-300 text-center mt-4 mb-4 text-xs lg:text-sm font-small transition-opacity ease-in duration-[1.1s] ${showSecondDescription ? "opacity-100" : "opacity-0"}`}>
            Beyond utility in the <a className='text-pink-500 underline' href="https://decent.land" target="_blank">decent.land</a> protocol, Arks are a prelude to the story told so far through <a className='text-pink-500 underline' href="https://leetyrrell.medium.com/list/a-decent-land-inprogress-novel-6f8c0bbf6a37" target="_blank">decent.land lore</a>. Each ark’s metadata contains a rich backstory from writer and art director Lee Tyrrell, with traits based on events in the narratives.
          </div>
          <div className={`text-gray-300 text-center mt-4 mb-4 text-xs lg:text-sm transition-opacity ease-in duration-[1.1s] ${showSecondDescription ? "opacity-100" : "opacity-0"}`}>
            The visual artist behind Arks is jrdsctt, a prolific graphic designer and photographer known for glitchy fine art 1/1s and innovating on how art can be manipulated with code.
          </div>
        </div>
      </div>
    </>
  )
}

export default MintPage;