import { ConnectWalletButton } from "./walletButtons"

export default function ConnectWalletInfoBox({appState}) {
  return (
    <div className="flex flex-col items-center my-8 py-8 max-w-md mx-auto bg-ark-purple-focused rounded-3xl drop-shadow-[0_0px_20px_rgba(0,255,87,0.5)]">
      <div className="text-white text-2xl px-8 my-8">Connect wallet to view</div>
      <div className="">
        <ConnectWalletButton appState={appState} />
      </div>
    </div>
  )
}